@import 'styles/common.scss';

.flagship {
  &Info {
    background: $white;
    margin-bottom: 4px;

    @include mobile {
      padding: 20px;
    }

    @include desktop {
      padding: 28px;
      border-radius: 10px;
    }
  }

  &Card {
    @include mobile {
      min-height: auto;
    }

    @include desktop {
      height: auto;
    }

    & > main {
      background: var(--bg-primary-webapp);
      border-radius: 0 0 10px 10px;

      & > p {
        -webkit-line-clamp: 1;
      }
    }
  }

  &Mobile {
    @include flex-column;
    row-gap: 16px;
  }
}
