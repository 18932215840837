@import "styles/common.scss";

.community-links {
    .links {
        @include flex-column;
        gap: 12px;
        .socials {
            @include flex(center);
            column-gap: 16px;
            padding: 8px 0;
            color: var(--text-icon-tertiary);
            .icon {
                padding: 0px;
            }
        }
        .custom-links {
            @include flex-column;
            gap: 10px;

            button > div {
                @include multi-line-ellipsis(1);
                word-break: break-word;
            }
            .link {
                @include multi-line-ellipsis(1);
                word-break: break-word;
                color: var(--text-icon-interactive);
            }
        }
    }
}

@include media-breakpoint-down(b768) {
    .community-links {
        .links {
            .socials {
                justify-content: center;
            }
        }
    }
}
