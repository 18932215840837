@import "styles/common.scss";

.container {
    background: var(--ui-teal-light-surface-tertiary);
    @include mobile {
        width: 100%;
        position: absolute;
        bottom: 0;
        border-radius: 20px 20px 0 0;
    }
    @include desktop {
        width: 460px;
    }
    .top {
        position: relative;
        .title {
            @include f-inter-semi-bold(1.6rem, 2.2rem);
            padding: 13px 0 3px;
            text-align: center;
        }
        .cancel-icon{
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 6px;
        }
    }
    .inputWrapper {
        @include flex-column;
        row-gap: 16px;
        padding: 22px 28px 28px;
        label {
           @include f-inter-regular-2(1.4rem, 2rem);
           margin-bottom: 4px;
        }
        input {
            padding: 9.5px 12.5px;
            border-width: 1.5px;
            border-radius: 10px;
        }
        .desc {
            color: var(--text-icon-on-lights-secondary);
            @include f-inter-regular(1.5rem, 2.4rem);
        }
        .error-message {
            @include f-inter-regular-2(1.2rem, 1.7rem);
            color: var(--text_icon-on_lights-critical);
            padding-top: 4px;
            &-icon {
                width: 15px;
                height: 15px;
            }
        }
    }
    .footer {
        @include flex(center, flex-end);
        background: var(--ui-teal-light-bg-primary);
        border-top: 1px solid var(--border-on-lights-10);
        @include mobile {
            padding: 20px;
        }
        @include desktop {
        padding: 28px;}
    }
}

