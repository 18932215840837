@import "styles/common.scss";

.opportunity-card {
    border: 1px solid var(--border-border-10, #080C0B1A);
    cursor: pointer;
}

@include media-breakpoint-down(b768) {
    .sidebar {
        margin: 0 -16px;
    }
    .flagship-events {
        margin: 0 -16px;
        padding-bottom: 0;
    }
    .community-links-content {
        padding: 0;
        border-top: 0;
    }
}

@include media-breakpoint-up(b768) {
    .sidebar {
        display: none;
    }
    .flagship-events {
        padding: 0;
        margin-top: 48px;
        .flagship-events-title {
            @include ui-heading-h2;
            margin-bottom: 16px;
            color: var(--text_icon-primary);
        }
    }
    .links {
        display: none;
    }
}
