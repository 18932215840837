@import "styles/common.scss";

.container {
    @include flex-column;

    .socials {
        @include flex(center);
        column-gap: 16px;
        padding: 8px 0;
        color: var(--text-icon-tertiary);
        .icon {
            padding: 0px;
        }
    }

    .actions {
        @include flex(center);
        column-gap: 6px;
        @include media-breakpoint-down(b768) {
            &.has-banner {
                margin-top: 20px;
            }
        }
        .message-icon {
            border: 1.5px solid var(--border-border-15);
            padding: 9.5px;
            border-radius: 10px;
        }
    }

    .members {
        @include flex-center;
        column-gap: 4px;
        &-count {
            @include ui-text-ui-13-medium;
            color: var(--text_icon-secondary);
        }
        .pics {
            padding: 0 8px 0 12px;
        }
    }
}
