@import "styles/common.scss";

.sidebar {
    @include flex-column;
}

.container {
    @include flex-column;
    .stats {
        padding: 16px;
        @include flex-column;
        gap: 8px;
    }
    .team-members {
        @include flex-column;
        position: relative;
        gap: 16px;
        .show-more-action {
            @include flex-center;
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 2px 0;
        }
    }
    .highlights {
        @include flex-column(flex-start);
        gap: 6px;
    }
    .offerings {
        @include ui-text-ui-14-22-regular;
        color: var(--text-icon-tertiary);
    }
    .topics {
        @include flex;
        flex-wrap: wrap;
        .topic-link {
            @include ui-text-ui-14-22-regular;
            color: var(--text-icon-interactive);
            &:hover {
                text-decoration: underline;
            }
        }
        .topic-separator {
            @include ui-text-ui-14-22-regular;
            color: var(--text-icon-tertiary);
            padding-right: 4px;
        }
    }
}

.partners {
    @include flex-column;
    gap: 12px;
    .partner {
        @include flex(center, flex-start);
    }
}

.team-member {
    @include flex(center, flex-start);
    column-gap: 12px;
    .avatar {
        background-color: var(--bg-primary-light)
    }
    .info {
        @include flex-column(flex-start, space-between);
        .name {
            @include ui-text-ui-14-semibold;
            color: var(--text-primary-v2);
        }
        .role {
            @include ui-text-ui-12-regular;
            color: var(--text-icon-tertiary);
        }
    }
}


@include media-breakpoint-down(b768) {
    .container {
        .stats {
            @include flex-column;
            gap: 6px;
        }
        .team-members {
            &:not(.expanded) {
                max-height: 320px;
                overflow: hidden;
            }
            .show-more-action {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, #FFFFFF 67.03%);
            }
        }
    }
    .partner {
        gap: 12px;
        .name {
            @include ui-text-ui-16-20-semibold;
        }
    }
    .community-links {
        display: none;
    }
}

@include media-breakpoint-up(b768) {
    .sidebar {
        gap: 20px;
    }
    .container, .community-links {
        background-color: var(--ui-teal-light-surface-tertiary);
        border-radius: 16px;
    }
    .partners-container {
        background-color: var(--surface-interactive-silent);
    }
    .container, .partners-container, .community-links {
        border-radius: 16px;
    }
    .links {
        .link {
            @include ui-text-ui-15-21-medium;
            text-decoration: underline;
            color: var(--text-icon-interactive);
        }
    }
    .team-members {
        &:not(.expanded) {
            max-height: 292px;
            overflow: hidden;
        }
        .show-more-action {
            background: linear-gradient(180deg, rgba(244, 246, 248, 0.2) 0%, #F4F6F8 67.03%);
        }
    }
    .content-section {
        padding: 16px;
        .title {
            @include ui-text-ui-16-20-semibold;
            margin-bottom: 12px;
            color: var(--text-icon-tertiary);
        }
    }
    .partner {
        gap: 8px;
        .link {
            @include ui-text-ui-15-21-medium;
            color: var(--text-icon-interactive);
        }
    }
}
