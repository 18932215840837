@import 'styles/common.scss';

$card-height: 222px;

.OpportunityCard {
  position: relative;
  background: var(--bg-primary);
  border-radius: 10px;

  @include mobile {
    min-height: $card-height;
    min-width: auto;
  }

  @include desktop {
    width: auto;
    height: $card-height;
  }

  .banner,
  .dummyBanner {
    display: block;
    height: 104px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
  }

  .dummyBanner {
    background: var(--complementary-primary-light);
  }

  .section {
    padding: 16px;

    @include mobile {
      @include flex-column;
    }
  }

  .header {
    .type {
      @include f-inter-bold(1.3rem, 1.6rem);
    }

    .event {
      color: #df303b;
    }

    .job {
      color: #009479;
    }

    .internship {
      color: #1d7bc3;
    }

    .startdate {
      @include f-inter-bold(1.3rem, 1.6rem);
      color: #ec3843;
      text-transform: uppercase;
    }
  }

  .title {
    @include f-inter-semi-bold(1.6rem, 2.2rem);
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 5px 0;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    height: 44px;
    word-break: break-word;
  }

  .info {
    @include f-inter-regular(1.3rem, 1.5rem);
    color: var(--dark-grey);
    width: 100%;
    flex-wrap: nowrap;
  }

  .infoTitle {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .infoDate {
    flex: 0 0 auto;
    justify-content: flex-end;
  }

  .dot {
    margin: 0 5px;
    color: var(--dot-color);
  }
}

.cardLink {
  min-width: 260px;
  display: block;

  @include mobile {
    min-width: 220px;
  }
}
